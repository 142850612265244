import { lazy, Suspense, useContext, useEffect } from 'react';
import CommonStore from './store/CommonStore';
import { history } from './history';
import 'antd/dist/antd.css';
import './App.css';
import { Provider } from 'mobx-react';
import { ConfigProvider } from 'antd';
import en from 'antd/lib/locale/en_GB';

const AppRouter = lazy(() => import('./router'));

const App = () => {
  const storeCommon = useContext(CommonStore);

  useEffect(() => {
    const temp = localStorage.getItem('token');
    if (temp) {
      storeCommon.setToken(temp);
    }

    window.addEventListener('storage', (e) => {
      const token = localStorage.getItem('token');
      if (token) {
        storeCommon.setToken(token);
      }

      if (!token) {
        storeCommon.setToken('');
        history.push('/login');
      }
    });
  }, [storeCommon]);

  return (
    <Suspense fallback={null}>
      <ConfigProvider locale={en}>
        <Provider storeCommon={storeCommon}>
          <AppRouter />
        </Provider>
      </ConfigProvider>
    </Suspense>
  );
};

export default App;

import { action, observable, makeObservable } from 'mobx';
import { createContext } from 'react';
import api from '../api/api';
import Config from '../Config';
import { User } from '../types';

class CommonStore {
  token: string | undefined = undefined;
  user: User | null = null;
  view: number = 0;
  orders: number = 0;
  cart: number = 0;

  constructor(
    token: string,
    user: User | null,
    view: number,
    orders: number,
    cart: number
  ) {
    makeObservable(this, {
      token: observable,
      user: observable,
      view: observable,
      orders: observable,
      cart: observable,
      setToken: action.bound,
      setUser: action.bound,
      setView: action.bound,
      setOrders: action.bound,
      setCart: action.bound,
    });

    this.token = token;
    this.user = user;
    this.view = view;
    this.orders = orders;
    this.cart = cart;

    this.setInitValues();
  }

  setInitValues = async () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    //
    const user = await api('/api/profile', {}, token);
    if (user?.id) {
      this.user = user;
    }

    //
    const ordeResp = await api(
      '/api/orders/list',
      {
        page: 1,
        pageSize: 1,
        filter: {
          status: [Config.orderStatuses.BackOrder],
          filter: '',
        },
      },
      token
    );
    if (ordeResp?.total) {
      this.orders = ordeResp?.total;
    }

    try {
      const cartData: { quantity: number }[] = await api(
        '/api/profile/cart',
        {},
        token
      );
      this.cart = cartData.reduce((accumulator, current) => {
        return accumulator + current.quantity;
      }, 0);
    } catch (error) {
      console.warn(error);
    }
  };

  setToken = (token: string) => {
    this.token = token;
  };

  setUser = (user: User | null) => {
    this.user = user;
  };

  setView = (view: number) => {
    this.view = view;
  };

  setOrders = (orders: number) => {
    this.orders = orders;
  };

  setCart = (cart: number) => {
    this.cart = cart;
  };
}

export default createContext(new CommonStore('', null, 0, 0, 0));

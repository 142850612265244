import Config from "../Config";

/**
 *
 * Fetch data from API
 * -------------------
 *
 * @param endpoint api endpoint without '/'
 * @param payload data
 * @param token auth token
 *
 * @returns Promise with data or null
 *
 */

const api = async (
  endpoint: string = "",
  payload: object = {},
  token: string = "",
  isFormData: boolean = false
): Promise<any | null> => {
  //
  // console.log(`API payload (${endpoint}):`, payload);

  //
  try {
    // console.log(`API url (${Config.api + endpoint})`);
    const response = await fetch(Config.api + endpoint, {
      method: "POST",
      headers: isFormData
        ? {
            Authorization: token ? "Bearer " + token : "",
          }
        : {
            Authorization: token ? "Bearer " + token : "",
            "Content-Type": "application/json",
          },
      body: isFormData ? (payload as FormData) : JSON.stringify(payload),
    });

    // console.log("API response:", response);

    // chack response status

    if (response.status === 401) {
      throw new Error("Unauthorized");
    }

    if (response.status === 400) {
      const result = await response.json();
      return result;
    }

    if (response.status === 200) {
      const result = await response.json();
      // console.log("API result:", result);
      return result;
    } else {
      const result = await response.json();
      // console.log("API status error:", result);
      return null;
    }
  } catch (err) {
    // console.log("API error:", err);
    return null;
  }
};

/**
 *
 */
export default api;
